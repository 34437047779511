@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* MARK: font-faces */
  @font-face {
    font-family: 'Martian';
    src: url('/src/static/fonts/MartianMono/MartianMonoNerdFont-Regular.ttf');
  }
  @font-face {
    font-family: '3270';
    src: url('/src/static/fonts/3270/3270NerdFont-Regular.ttf');
    font-weight: bold;
  }
  @font-face {
    font-family: 'Bebas';
    src: url('/src/static/fonts/Bebas_Neue/BebasNeue-Regular.ttf');
  }
  @font-face {
    font-family: 'NeonGlow';
    src: url('/src/static/fonts/neon-glow-font/NeonGlowBold-YxJv.ttf');
  }
  @font-face {
    font-family: 'MarqueeTitle';
    src: url('/src/static/fonts/NeonSpark-ovg0q.otf');
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :root {
    /* MARK: Borders */
    .brick-wall-border {
      border-image: url('/src/static/imgs/BrickWallSolidYellow.png') 225 round;
      border-style: solid;
    }
    .string-light-border {
      border-image: url('/src/static/imgs/StringLight.png') 200 round;
      border-style: solid;
    }

    /* MARK: Backgrounds */
    .neon-green-light-bg {
      background-image: url('/src/static/imgs/NeonGreenLight.png');
      background-size: 1.5vh;
      background-position-y: -0.1vh;
    }
    .marquee-pillar-bg {
      background-image: url('/src/static/imgs/MarqueePillar.png');
      background-size: 100%;
    }
    .top-piece-bg {
      background-image: -moz-radial-gradient(center, #fbbe24 0%, #17140f 85%, #17140f 100%);
      background-image: -webkit-radial-gradient(center, #fbbe24 0%, #17140f 85%, #17140f 100%);
      background-image: radial-gradient(ellipse at center, #fbbe24 0%, #17140f 85%, #17140f 100%);
    }
    .lined-bg {
      background: linear-gradient(90deg, #b1b197 calc(100% - 1px), #1c1812 0);
      background-size: calc((100% - 6 * 1px) / 7 + 1px) 100%;
    }
    .lined-h-bg {
      background: linear-gradient(to bottom, #d4d4d8, #d4d4d8 45%, #0f0e0e 45%, #0f0e0e 55%, #d4d4d8);
      background-size: 100% 0.2rem;
    }
    .marquee-desc-lined-bg {
      background: linear-gradient(to bottom, #fff2c4c8, #fff2c4c8 94%, #8d8c8c88 96%, #8d8c8c88);
      background-size: 100% 26px;
    }
    .marquee-desc-bg {
      background: radial-gradient(ellipse at center, #fffc58, black);
      /* background-size: contain; */
    }
    .diag-45-bg {
      background: linear-gradient(
        to bottom right,
        transparent calc(50% - 2.25px),
        #bdb8a2 calc(50% - 2px),
        #bdb8a2 calc(50% + 2px),
        #cac8c0 calc(50% + 2.25px)
      );
      background-size: 100%;
    }
    .diag-315-bg {
      background: linear-gradient(
        to bottom left,
        transparent calc(50% - 2.25px),
        #e6e0a7 calc(50% - 2px),
        #e6e0a7 calc(50% + 2px),
        #e5e5da calc(50% + 2.25px)
      );
      background-size: 100%;
    }
    .triangle-tr-bg {
      background: linear-gradient(to top right, transparent calc(40%), #c9c3ac calc(40%), #c9c3ac calc(60%), #e5e5da calc(60%));
      background-size: 100%;
    }
    .triangle-tl-bg {
      background: linear-gradient(to top left, transparent calc(40%), #bdb8a2 calc(40%), #bdb8a2 calc(60%), #cac8c0 calc(60%));
      background-size: 100%;
    }

    /* MARK: Fonts */
    --font-marquee-title: 'MarqueeTitle', serif;
    --font-martian: 'Martian', sans-serif;
    --font-3270: '3270', serif;
    --font-bebas: 'Bebas', sans-serif;
    --font-neon-glow: 'NeonGlow', sans-serif;
    font-family: var(--font-martian);
    .my-font-marquee-title {
      font-family: var(--font-marquee-title);
    }
    .my-font-neon-glow {
      font-family: var(--font-neon-glow);
    }
    .my-font-bebas {
      font-family: var(--font-bebas);
    }
  }

  /* MARK: Animations */
  @keyframes flicker {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0.75;
    }
    40% {
      opacity: 0.95;
    }
    60% {
      opacity: 0.85;
    }
    80% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes flicker-constant {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 0.85;
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 0.9;
    }
    40% {
      opacity: 0.95;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 0.85;
    }
    70% {
      opacity: 0.95;
    }
    80% {
      opacity: 0.5;
    }
    83% {
      opacity: 0.85;
    }
    87% {
      opacity: 0.25;
    }
    90% {
      opacity: 0.95;
    }
    100% {
      opacity: 1;
    }
  }

  body {
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
}
